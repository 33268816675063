import React from 'react'

import loadable from '@loadable/component'
// const Cookies = loadable(() => import('../components/Cookies'), {
//   resolveComponent: components => components.Cookies,
// })
const LandingNavbar = loadable(() => import('../components/UI/Navbar'), {
  resolveComponent: components => components.LandingNavbar,
})

import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Footer } from '../components/LandingComponents'
import { isAGoogleAdVariant } from '../config/adVariants'
/**
 * @typedef ILayoutProps
 * @props {React.ReactNode} children - the container children.
 * @props {'primary'|'secondary'} - style of nav
 */

export interface ILayoutProps {
  children: React.ReactNode
  navbarProps?: {
    style?: 'primary' | 'secondary'
    getStartedHref?: string
    page?: 'home' | 'paid' | 'students' | 'quote' | 'agency' | 'agency-quote'
  }
}

/**
 * Renders the layout with components.
 *
 * @param {React.ReactNode} children - the container children.
 * @param {'primary'|'secondary'} - style of nav
 * @example
 * <LandingLayout>
 *  { children }
 * </LandingLayout>
 */

const LandingLayout = ({
  children,
  navbarProps = { style: 'primary' },
}: ILayoutProps): JSX.Element => {
  return (
    <div className='w-full min-h-screen overflow-x-hidden'>
      <LandingNavbar {...navbarProps} />
      <div className='pt-0'>{children}</div>
      {/* <Cookies /> */}
      {!isAGoogleAdVariant(window.location.pathname) && (
        <LazyLoadComponent>
          <Footer />
        </LazyLoadComponent>
      )}
    </div>
  )
}

export default LandingLayout
