import { CreditsBalance, ICreditsBalance } from './CreditsBalance'

export type TSegmentTypes = 'free_user' | 'paid_user' | 'unkown'

/**
 * User interface
 */
export interface IUser {
  id?: string
  email?: string
  status?: string
  first_name?: string
  last_name?: string
  provider_id?: string
  consents_accepted_at?: Date
  consents_accepted_ver?: string
  city?: string
  languages?: string[]
  country_of_residence?: string
  university?: string
  date_of_birth?: Date | null | undefined
  gender?: string
  occupation?: string
  finished_education?: string
  province?: string
  tips_notifications?: boolean
  obligatory_notifications?: boolean
  credits_balance?: ICreditsBalance
  onboarding_visited?: boolean
  segment_type?: TSegmentTypes
  segment_anonymous_id?: string
  last_access?: Date
  filled_surveys?: number
  has_received_first_survey_credits?: boolean
  has_given_review?: boolean
  is_system_user?: boolean
  active?: boolean
  created_at?: Date
  updated_at?: Date
  has_received_initial_credits?: boolean
  can_post_social_groups?: boolean
  current_respondents?: number
  required_respondents?: number
  active_surveys_count?: number
  surveys_count?: number
  need_update_profile?: boolean
  survey_schemas_count?: number
}

/**
 * User model.
 */
export class User {
  id?: string
  email?: string
  status?: string
  first_name?: string
  last_name?: string
  provider_id?: string
  consents_accepted_at?: Date
  consents_accepted_ver?: string
  city?: string
  languages?: string[]
  country_of_residence?: string
  university?: string
  date_of_birth?: Date | null | undefined
  gender?: string
  occupation?: string
  finished_education?: string
  province?: string
  tips_notifications?: boolean
  obligatory_notifications?: boolean
  credits_balance?: ICreditsBalance
  onboarding_visited?: boolean
  segment_type?: TSegmentTypes
  segment_anonymous_id?: string
  last_access?: Date
  filled_surveys?: number
  has_received_first_survey_credits?: boolean
  has_given_review?: boolean
  is_system_user?: boolean
  active?: boolean
  created_at?: Date
  updated_at?: Date
  has_received_initial_credits?: boolean
  can_post_social_groups?: boolean
  current_respondents?: number
  required_respondents?: number
  active_surveys_count?: number
  surveys_count?: number
  survey_schemas_count?: number // created via survey builder
  need_update_profile?: boolean

  constructor(props: IUser) {
    this.id = props.id
    this.status = props.status
    this.email = props.email
    this.first_name = props.first_name
    this.last_name = props.last_name
    this.provider_id = props.provider_id
    this.consents_accepted_at = props.consents_accepted_at
    this.consents_accepted_ver = props.consents_accepted_ver
    this.city = props.city
    this.languages = props.languages
    this.country_of_residence = props.country_of_residence
    this.university = props.university
    this.date_of_birth = props.date_of_birth
    this.gender = props.gender
    this.occupation = props.occupation
    this.finished_education = props.finished_education
    this.province = props.province
    this.tips_notifications = props.tips_notifications
    this.obligatory_notifications = props.obligatory_notifications
    this.credits_balance = new CreditsBalance(
      props.credits_balance as ICreditsBalance,
    )
    this.onboarding_visited = props.onboarding_visited
    this.segment_type = props.segment_type
    this.segment_anonymous_id = props.segment_anonymous_id
    this.active = props.active
    this.last_access = props.last_access
    this.filled_surveys = props.filled_surveys
    this.created_at = new Date(props.created_at)
    this.updated_at = new Date(props.updated_at)
    this.has_given_review = props.has_given_review
    this.is_system_user = props.is_system_user
    this.has_received_first_survey_credits =
      props.has_received_first_survey_credits
    this.has_received_initial_credits = props.has_received_initial_credits
    this.can_post_social_groups = props.can_post_social_groups
    this.current_respondents = props.current_respondents
    this.required_respondents = props.required_respondents
    this.active_surveys_count = props.active_surveys_count
    this.surveys_count = props.surveys_count
    this.need_update_profile = props.need_update_profile
    this.survey_schemas_count = props.survey_schemas_count
  }

  /**
   * Get first and last name. If both not set, returns empty string.
   */
  getName(): string {
    let name = ''
    if (this.first_name) {
      name = `${name} ${this.first_name}`
    }
    if (this.last_name) {
      name = `${name} ${this.last_name}`
    }
    return name
  }

  /**
   * Get first name of undefined.
   */
  getFirstName(): string | undefined {
    return this.first_name
  }

  shouldDisplayReview(): boolean {
    return (
      this.isOlderThanTwoDays() &&
      this.filled_surveys &&
      this.filled_surveys > 3 &&
      !this.has_given_review
    )
  }

  hasReceivedInitialCredits(): boolean | undefined {
    return this.has_received_initial_credits
  }

  isOlderThanTwoDays(): boolean | undefined {
    const now = new Date()
    const twoDaysInMs = 3 * 24 * 60 * 60 * 1000

    const timeDiffInMs = now.getTime() - this.created_at.getTime()
    return timeDiffInMs >= twoDaysInMs
  }

  isValidForSurveyAnalytics(): boolean {
    const turnOffSurveyAlanyticsDate = new Date('2024-07-15')
    return this.created_at < turnOffSurveyAlanyticsDate
  }
}
