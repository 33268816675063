export const getGaClientId = () => {
  const gaCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('_ga='))

  if (gaCookie) {
    const gaClientId = gaCookie.split('=')[1].split('.').slice(2).join('.')
    return gaClientId
  }

  return null
}

// GA4_MEASUREMENT_ID: 'G-Q7CVD89HYC'
// cookie store: _ga_Q7CVD89HYC
export const getGaSessionId = () => {
  const ga4SessionCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('_ga_Q7CVD89HYC='))

  if (ga4SessionCookie) {
    const regExp = /(?<=GS1\.1\.)\d+/gm
    const matches = ga4SessionCookie.match(regExp)
    return matches[0]
  }

  return null
}

export const getGA4MeasurementParams = () => {
  return {
    client_id: getGaClientId(),
    session_id: getGaSessionId(),
  }
}
